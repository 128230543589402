import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Alert from "../../../../Common/Alert";
import DatatableFunc from "../../../../Common/DatatableFunc";
import Http from "../../../../Common/Http";
import IsLogin from "../../../../Common/IsLogin";

const List = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [inputs, setInputs] = useState({});
  const [receiveData, setReceiveData] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [circular, setCircular] = useState({});
  const [listSelect, setListSelect] = useState({});
  const [alert, setAlert] = useState(null);
  const { id } = useParams();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const res = await Http.get("/rfq-sent-from-vendor/" + id);
        setReceiveData(res.data.info);
        setCircular(res.data.circular);

        // if (receiveData.length === 0) return;

        // const findLowestPricedItems = () => {
        //   const productsMap = {};

        //   receiveData.forEach((info) => {
        //     info.items.forEach((item) => {
        //       const productId = item.product_id;
        //       const unitPrice = parseFloat(item.unit_price);

        //       if (
        //         !productsMap[productId] ||
        //         unitPrice < parseFloat(productsMap[productId].unit_price)
        //       ) {
        //         productsMap[productId] = {
        //           quotation_item_sent_from_vendor_id:
        //             item.quotation_sent_from_vendor_id,
        //           unit_price: item.unit_price,
        //         };
        //       }
        //     });
        //   });

        //   // Transform productsMap to only have ids
        //   const list = {};
        //   Object.keys(productsMap).forEach((productId) => {
        //     list[productId] =
        //       productsMap[productId].quotation_item_sent_from_vendor_id;
        //   });

        //   setListSelect(list);
        // };

        // findLowestPricedItems();

        const res2 = await Http.get("/circular-rfq/" + id + "/edit");
        setItemList(res2.data.items);

        const res3 = await Http.get("/final-quotation/" + id);
        if (res3.data.items && res3.data.items.length > 0) {
          const list = {};
          res3.data.items.forEach((item) => {
            list[item.product_id] = item.quotation_item_sent_from_vendor_id;
            // list[item.quotation_item_sent_from_vendor_id] =
            //   item.quotation_item_sent_from_vendor_id;
          });
          setListSelect(list);
        } else {
          console.log("No Final Quotation found.");
        }
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    DatatableFunc();
    fetchData();
  }, [id]);

  // const fetchData = () => {
  //   Http.get("/rfq-sent-from-vendor/" + id).then((res) => {
  //     setReceiveData(res.data.info);
  //     setCircular(res.data.circular);
  //   });
  //   Http.get("/circular-rfq/" + id + "/edit").then((res) => {
  //     setItemList(res.data.items);
  //   });
  //   Http.get("/final-quotation/" + id).then((res) => {
  //     if (res.data.items && res.data.items.length > 0) {
  //       const list = {};
  //       res.data.items.forEach((item) => {
  //         // list[item.product_id] = item.quotation_item_sent_from_vendor_id;
  //         list[item.quotation_item_sent_from_vendor_id] = item.quotation_item_sent_from_vendor_id;
  //       });
  //       setListSelect(list);
  //     } else {
  //       console.log("No Final Quotation found.");
  //     }
  //   });

  // };

  // useEffect(() => {
  //   DatatableFunc();
  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   if (receiveData.length === 0) return;

  //   const findLowestPricedItems = () => {
  //     const productsMap = {};

  //     receiveData.forEach((info) => {
  //       info.items.forEach((item) => {
  //         const productId = item.product_id;

  //         if (
  //           !productsMap[productId] ||
  //           productsMap[productId].unit_price > parseFloat(item.unit_price)
  //         ) {
  //           productsMap[productId] = item.quotation_item_sent_from_vendor_id;
  //         }
  //       });
  //     });

  //     setListSelect(productsMap);
  //   };

  //   findLowestPricedItems();
  // }, [receiveData]);

  // useEffect(() => {
  //   if (receiveData.length === 0) return;

  //   const findLowestPricedItems = () => {
  //     const productsMap = {};

  //     receiveData.forEach((info) => {
  //       info.items.forEach((item) => {
  //         const productId = item.product_id;
  //         const unitPrice = parseFloat(item.unit_price);

  //         if (
  //           !productsMap[productId] ||
  //           unitPrice < parseFloat(productsMap[productId].unit_price)
  //         ) {
  //           productsMap[productId] = {
  //             quotation_item_sent_from_vendor_id: item.quotation_sent_from_vendor_id,
  //             unit_price: item.unit_price
  //           };
  //         }
  //       });
  //     });

  //     // Transform productsMap to only have ids
  //     const list = {};
  //     Object.keys(productsMap).forEach((productId) => {
  //       list[productId] = productsMap[productId].quotation_item_sent_from_vendor_id;
  //     });

  //     setListSelect(list);
  //   };

  //   findLowestPricedItems();
  // }, [receiveData]);

  useEffect(() => {
    if (receiveData.length === 0) return;

    const findLowestPricedItems = () => {
      const productsMap = {};

      receiveData.forEach((info) => {
        info.items.forEach((item) => {
          const productId = item.product_id;
          const unitPrice = parseFloat(item.unit_price);

          // Initialize or update the product in the map if it has a lower price
          if (
            !productsMap[productId] ||
            unitPrice < parseFloat(productsMap[productId].unit_price)
          ) {
            productsMap[productId] = {
              quotation_item_sent_from_vendor_id: item.id, unit_price: item.unit_price,
            };
          }
        });
      });

      // Convert productsMap to listSelect with the lowest priced item IDs
      const list = {};
      Object.keys(productsMap).forEach((productId) => {
        list[productId] =
          productsMap[productId].quotation_item_sent_from_vendor_id;
        console.log("Received data:", receiveData);
        console.log("Products map:", productsMap);
        console.log("Final listSelect:", list);
      });

      setListSelect(list);
    };

    findLowestPricedItems();
  }, [receiveData]);

  const toggleItemSelect = (productId, vendorItemId) => {
    setListSelect((prevListSelect) => ({
      ...prevListSelect,
      [productId]: vendorItemId,
    }));
  };

  const formSubmit = () => {
    inputs.data = listSelect;
    inputs.circular = id;
    Http.post("/final-quotation", inputs)
      .then((res) => {
        navigate("/circular-rfq", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch((e) => {
        setError(e.response.data.errors);
      });
  };

  console.log('====================================');
  console.log(listSelect);
  console.log('====================================');

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">{circular.title}</h5>
          </div>
          <div className="am-pagebody">
            <Alert alert={alert} />
            <div className="card pd-20 pd-sm-40">
              {loading ? (
                <ProgressSpinner />
              ) : (
                <>
                  <div className="row">
                    <div className="table-responsive p-0 pb-2">
                      <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 _datatable">
                        <thead>
                          <tr>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Sl
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Vendors
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Date
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Validity Date
                            </th>
                            {itemList.map((item, index) => (
                              <th
                                key={index}
                                className="text-uppercase text-dark text-sm font-weight-bolder ps-2"
                              >
                                <table style={{ width: "100%" }}>
                                  <thead>
                                    <tr>
                                      <th
                                        colSpan={7}
                                        style={{ textAlign: "center" }}
                                      >
                                        {item.product ? item.product.name : ""}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th>Qty</th>
                                      <th>Price</th>
                                      <th>Vat</th>
                                      <th>Tax</th>
                                      <th>Amount</th>
                                      <th>Description</th>
                                    </tr>
                                  </thead>
                                </table>
                              </th>
                            ))}
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Remarks
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {receiveData.map((item, index) => (
                            <tr key={item.id}>
                              <td className="text-xs">{index + 1}</td>
                              <td className="text-xs">{item.vendor.name}</td>
                              <td className="text-xs">{item.date}</td>
                              <td className="text-xs">
                                {item.quotation_validity_date}
                              </td>
                              {item.items.map((x, i) => (
                                <td
                                  key={i}
                                  className={
                                    "text-xs " +
                                    (listSelect[x.product_id] === x.id
                                      ? "selected"
                                      : "")
                                  }
                                  onClick={() =>
                                    toggleItemSelect(x.product_id, x.id)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <table style={{ width: "100%" }}>
                                    <tbody>
                                      <tr key={x.id}>
                                        <td>{x.qty}</td>
                                        <td>{x.unit_price}</td>
                                        <td>{x.vat > 0 ? x.vat : 0.0}</td>
                                        <td>{x.tax > 0 ? x.tax : 0.0}</td>
                                        <td>{x.actual_amount}</td>
                                        <td>{x.remarks}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              ))}
                              <td className="text-xs">{item.remarks}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {Object.keys(listSelect).length > 0 ? (
                    <div className="row mt-3">
                      <div className="col-6"></div>
                      <div className="col-3"></div>
                      <div className="col-3">
                        <button
                          className="btn btn-primary btn-block mg-b-10"
                          onClick={formSubmit}
                        >
                          <i className="fa fa-send mg-r-10"></i> Create
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
