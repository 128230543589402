import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import { LoaderComponent } from "../../../Common/LoaderComponent";
import { ProgressSpinner } from "primereact/progressspinner";

const Edit = (props) => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const { id } = useParams();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);



  // const fetchData = () => {
  //   Http.get("/asset-category/" + id + "/edit").then((res) => {
  //     setInputs({
  //       name: res.data.name,
  //       status: res.data.status,
  //     });
  //   });
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    setLoading(true);
  
    const fetchData = async () => {
      try {
        const res = await Http.get("/asset-category/" + id + "/edit");
        setInputs({
          name: res.data.name,
          status: res.data.status,
        });
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const formSubmit = () => {
    Http.put("/asset-category/" + id, inputs)
      .then((res) => {
        navigate("/asset-category", {
          state: { message: res.data.message, type: res.data.type },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Asset Category</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Edit Asset Category Form</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            {loading ? (
    <ProgressSpinner />
  ) : (
    <>


            <div className="row">
              <div className="col-lg">
                <label>
                  Category Name <i className="fa fa-star required"></i>
                </label>
                <input type="text" className="form-control" name="name" value={inputs.name || ""} onChange={handleChange} placeholder="Input Name" />
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>Status</label>
                <select className="form-control" name="status" onChange={handleChange}>
                  <option value="active" selected={inputs.status === "active" ? true : false}>
                    Active
                  </option>
                  <option value="inactive" selected={inputs.status === "inactive" ? true : false}>
                    inactive
                  </option>
                </select>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <button className="btn btn-primary btn-block mg-b-10" onClick={formSubmit}>
                  <i className="fa fa-send mg-r-10"></i> Update
                </button>
              </div>
            </div>
    </>)}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Edit;
