import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

const CreateRegion = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [list, setList] = useState([{ h_name: "", date: "" }]);
  const { checkRequiredFields } = useRequiredFields();

  // Get current date for date input
  useEffect(() => {
    const getCurrentDateInput = () => {
      const dateObj = new Date();

      // get the month in this format of 04, the same for months
      const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
      const day = ("0" + dateObj.getDate()).slice(-2);
      const year = dateObj.getFullYear();

      const shortDate = `${year}-${month}-${day}`;

      setList([{ h_name: "", date: shortDate }]);
    };

    getCurrentDateInput();
  }, []);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const newList = [...list];
    newList[index][name] = value;
    setList(newList);
  };

  // Add More List
  const addMoreList = () => {
    setList([...list, { h_name: "", date: "" }]);
  };

  // Remove List
  const removeList = (index) => {
    // Load All Existing experience State Data
    const newList = [...list];
    // Remove Experience by Index/Click Event Wise
    newList.splice(index, 1);
    // Replace Existing experience into State
    setList(newList);
  };

  const formSubmit = () => {
    if (checkRequiredFields()) {
      Http.post("/holiday", { ...inputs, list })
        .then((res) => {
          navigate("/holiday-list", {
            state: { message: res.data.message, type: "success" },
          });
        })
        .catch(function (e) {
          setError(e.response.data.errors);
        });
    }
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Holiday Calander</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <div className="row">
              <div className="col-8">
                <h6 className="card-body-title">Create Holiday</h6>
              </div>
              <div className="col-4 text-right">
                <button className="btn btn-info" onClick={addMoreList}>
                  <i className="fa fa-plus mg-r-10"></i> ADD More
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            {Array.from(list).map((x, i) => (
              <div className="row mt-4" key={i}>
                <div className="col-lg">
                  <label className="font-weight-bold">Holiday Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="h_name"
                    value={x.h_name || ""}
                    onChange={(e) => handleInputChange(e, i)}
                    placeholder="Input Name"
                    data-required
                  />
                </div>
                {/* <div className="col-lg">
                  <label className="font-weight-bold">Remarks</label>
                  <input
                    type="text"
                    className="form-control"
                    name="remarks"
                    value={x.remarks || ""}
                    onChange={(e) => handleInputChange(e, i)}
                    placeholder="Remarks"
                  />
                </div> */}
                <div className="col-lg">
                  <div className="row">
                    <div className="col-10">
                      <label className="font-weight-bold">Date</label>
                      <input
                        type="date"
                        className="form-control"
                        name="date"
                        value={x.date || ""}
                        onChange={(e) => handleInputChange(e, i)}
                        data-required
                      />
                    </div>
                    <div className="col-2 text-right">
                      {list.length > 1 ? (
                        <>
                          <div className="text-white" style={{ marginBottom: ".7rem" }}>
                            .
                          </div>
                          <button className="btn btn-danger btn-sm" onClick={() => removeList(i)}>
                            <i className="fa fa-trash"></i>
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="row mt-3">
              <div className="col-3">
                <button className="btn btn-primary btn-block mg-b-10" onClick={formSubmit}>
                  <i className="fa fa-send mg-r-10"></i> Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRegion;
