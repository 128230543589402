import React from "react";
import { Link } from "react-router-dom";

const Sales = ({ role, onMenuClick, active }) => {
  return (
    <>
      <li className="nav-item">
        <Link to className={`nav-link with-sub ${active === "/primary-sales" ? "active show-sub" : ""}`}>
          <i className="fa fa-briefcase" aria-hidden="true"></i>
          <span>Sales</span>
        </Link>
        <ul className="nav-sub">
          {role === "admin" && (
            <li className="nav-item">
              <Link
                to="/primary-sales"
                className={`nav-link ${active === "/primary-sales" ? "active" : ""}`}
                onClick={() => onMenuClick("/primary-sales")}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Primary Sales
              </Link>
            </li>
          )}
        </ul>
      </li>
    </>
  );
};

export default Sales;
