import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

import useAlertFromLocation from "../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../hooks/useDeleteData";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const navigate = useNavigate();
  const [receiveData, setReceiveData] = useState([]);
  const [authData, setAuthData] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [res, res2] = await Promise.all([
        Http.get("/bill-submission"),
        Http.get("/login-staff-detail"),
      ]);

      setReceiveData(res.data);
      setAuthData(res2.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // to delete data call
  const deleteData = useDeleteData(fetchData);
  // to show alert message
  const alert = useAlertFromLocation();

  const slipApproved = (id) => {
    Http.get("/bill-submission-approved/" + id + "/1")
      .then((res) => {
        navigate("/billsubmission", {
          state: { message: res.data.message, type: "success" },
        });

        Http.get("/bill-submission").then((res) => {
          setReceiveData(res.data);
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };
  const slipReject = (id) => {
    Http.get("/bill-submission-approved/" + id + "/0")
      .then((res) => {
        navigate("/billsubmission", {
          state: { message: res.data.message, type: "success" },
        });

        Http.get("/bill-submission").then((res) => {
          setReceiveData(res.data);
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };


  console.log('====================================');
  console.log(receiveData);
  console.log('====================================');

  // const cols = [
  //   // {
  //   //   field: (item) => (item.staff ? item.staff.name : ""),
  //   //   header: "Name",
  //   // },
  //   // { field: "staff.name", header: "Name" },
  //   // { field: "submitted_date", header: "Submitted Date" },
  //   {
  //     field: (item) => (item.staff ? item.staff.name : ""),
  //     header: "Name",
  //   },
  //   // { field: "total_amount", header: "Submitted Date" },
  //   // {
  //   //   field: (item) => Number(item.total_amount).toFixed(2),
  //   //   header: "Total Amount",
  //   //   style: { textAlign: "right" },
  //   // },
  //   { field: "type", header: "Type" },
  //   { field: "remarks", header: "Remarks" },
  //   // { field: "recommended", header: "Recommended" },
  //   { field: "approved", header: "Approved" },
  //   { field: "status", header: " Status" },
  // ];

  
  const cols = [
    {
      field: (item) => (item.staff ? item.staff.name : ""),
      header: "Name",
    },
    { field: "submitted_date", header: "Submitted Date" },

    { field: "type", header: "Type" },
    { field: "total_amount", header: "Amount" },
    { field: "remarks", header: "Remarks" },
    
    { field: "approved.name", header: "Approved by" },
    { field: "status", header: " Status" },
  ];

  const actionTemplate = (rowData, deleteData) => {
    return (
      <div className="text-xs text-nowrap">
        {(authData.user_type === "admin" || authData.approved_by > 0) &&
          rowData.approved_by < 1 && (
            <button
              className="btn btn-primary btn-sm ms-1"
              onClick={() => slipApproved(rowData.id)}
            >
              <i className="fa fa-check"></i>
            </button>
          )}
        {(authData.user_type === "admin" || authData.approved_by > 0) &&
          rowData.approved_by > 0 && (
            <button
              className="btn btn-info btn-sm ms-1"
              onClick={() => slipReject(rowData.id)}
            >
              <i className="fa fa-minus"></i>
            </button>
          )}
        {(authData.user_type === "admin" || authData.user_type === "hr") &&
          rowData.approved_by < 1 && (
            <button
              className="btn btn-danger btn-sm ms-1"
              onClick={() =>
                deleteData(rowData.id, "bill-submission", "Bill Submission")
              }
            >
              <i className="fa fa-trash"></i>
            </button>
          )}
        <Link
          to={{
            pathname: "/bill-submission-view/" + rowData.id,
          }}
          className="btn btn-info btn-sm ms-1"
        >
          <i className="fa fa-list" aria-hidden="true"></i>
        </Link>
      </div>
    );
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Bill Submissions</h5>
            <Link to="/create-bill-submission" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i>Bill Submission
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-20 pd-sm-40 shadow">
              <div className="row">
                <div className="table-responsive p-0 pb-2">
                  {/* <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 datatable">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          S/N
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Name
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Submitted Date
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Total Amount
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Remarks
                        </th>
                        
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Approved
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Status
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {receiveData.map((item, index) => (
                        <tr key={item.id}>
                          <td className="text-xs">{index + 1}</td>
                          <td className="text-xs">
                            {item.staff ? item.staff.name : ""}
                          </td>
                          <td className="text-xs">{item.submitted_date}</td>
                          <td className="text-xs">{item.total_amount}</td>
                          <td className="text-xs">{item.remarks}</td>
                          <td className="text-xs">
                            {item.approved != null ? item.approved.name : ""}
                          </td>
                          <td className="text-xs">{item.status}</td>
                          <td className="text-xs">
                            {(authData.user_type === "admin" ||
                              authData.approved_by > 0) &&
                              item.approved_by < 1 && (
                                <button
                                  className="btn btn-primary btn-sm ms-1"
                                  onClick={() => slipApproved(item.id)}
                                >
                                  <i className="fa fa-check"></i>
                                </button>
                              )}
                            {(authData.user_type === "admin" ||
                              authData.approved_by > 0) &&
                              item.approved_by > 0 && (
                                <button
                                  className="btn btn-info btn-sm ms-1"
                                  onClick={() => slipReject(item.id)}
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                              )}
                            {(authData.user_type === "admin" ||
                              authData.user_type === "hr") &&
                              item.approved_by < 1 && (
                                <button
                                  className="btn btn-danger btn-sm ms-1"
                                  onClick={() =>
                                    deleteData(
                                      item.id,
                                      "bill-submission",
                                      "Bill Submission"
                                    )
                                  }
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              )}
                            <Link
                              to={{
                                pathname: "/bill-submission-view/" + item.id,
                              }}
                              className="btn btn-info btn-sm ms-1"
                            >
                              <i className="fa fa-list" aria-hidden="true"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                </div>

                <ReactDataTable
                  cols={cols}
                  receiveData={receiveData}
                  isLoading={loading}
                  actionTemplate={actionTemplate}
                  deleteData={deleteData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
