import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import useRequiredFields from "../../../../../hooks/useRequiredFields";
import FormValidation from "../../../../Common/FormValidation";
import Http from "../../../../Common/Http";
import IsLogin from "../../../../Common/IsLogin";
import ReactDropdown from "../../../../Common/ReactDropdown";

const CreateArea = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [region, setRegion] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const { checkRequiredFields } = useRequiredFields();

  const getCurrentDateInput = () => {
    const dateObj = new Date();
    // get the month in this format of 04, the same for months
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();
    const shortDate = `${year}-${month}-${day}`;
    setInputs((values) => ({ ...values, ["started_from"]: shortDate }));
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/regionSelect2");
      setRegion(res.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    getCurrentDateInput();
  }, []);
  // End Default Load

  const selectHandleChange = (selectedOption, actionMeta) => {
    const name = actionMeta.name;
    const value = selectedOption.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const formSubmit = () => {
    if (checkRequiredFields()) {
      Http.post("/area", inputs)
        .then((res) => {
          navigate("/area", {
            state: { message: res.data.message, type: "success" },
          });
        })
        .catch(function (e) {
          setError(e.response.data.errors);
        });
    }
  };
  const reactDropdownHandler = (field, prop) => (e) => {
    setInputs((values) => ({
      ...values,
      [field]: prop ? e.value[prop] : e.value,
    }));
  };


  return (
    <div>
      <IsLogin></IsLogin>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title"> Create Area </h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">
            Create Area Form
            </h6>
            {error && Object.keys(error).length > 0 && (
              <div className="row">
                <div className="col-lg">
                  <FormValidation error={error} />
                </div>
              </div>
            )}
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label className="font-weight-bold" htmlFor="">
                      Select Region <i className="fa fa-star required"></i>
                    </label>
                    {/* <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={region[0]}
                      name="work_place_region_id"
                      options={region}
                      onChange={selectHandleChange}
                    /> */}
                    <ReactDropdown
                      options={region}
                      optitle={"label"}
                      onChange={reactDropdownHandler("work_place_region_id")}
                    />

                    {/* <select
                  className="form-control"
                  name="work_place_region_id"
                  onChange={handleChange}
                >
                  <option value="">Select Region</option>
                  {Array.from(region).map((item, index) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select> */}
                  </div>
                  <div className="col-lg">
                    <label className="font-weight-bold" htmlFor="">
                      Name <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={inputs.name || ""}
                      onChange={handleChange}
                      placeholder="Input Name"
                      data-required
                    />
                  </div>
                  <div className="col-lg">
                    <label className="font-weight-bold" htmlFor="">
                      Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="started_from"
                      value={inputs.started_from || ""}
                      onChange={handleChange}
                      data-required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg mt-3">
                    <label className="font-weight-bold" htmlFor="">
                      Remarks
                    </label>
                    <textarea
                      className="form-control"
                      name="remarks"
                      value={inputs.remarks || ""}
                      onChange={handleChange}
                      placeholder="Remarks"
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default CreateArea;
