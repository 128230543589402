import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Alert from "../../../Common/Alert";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

import useDeleteData from "../../../../hooks/useDeleteData";
import ReactDataTable from "../../../Common/ReactDataTable";

const LeaveRules = () => {
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  // to fetch data from the server
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/leave-rules");
      setReceiveData(res.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  // to fetch data at the initial render
  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  // to delete data
  const deleteData = useDeleteData(fetchData);

  // to show alert message
  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  // to show alert message from location state
  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  // columns for the datatable
  const cols = [
    {
      field: (item) => (item.department ? item.department.name : "All"), // to show the department name (if exists) or "All" (if not exists
      header: "Department",
    },
    { field: "type", header: "Type" },
    { field: "earn_eligable_date", header: "Eligable Date" },
    { field: "total_days", header: "Total Days" },
  ];

  return (
    <div>
      <IsLogin></IsLogin>
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Leave Rules</h5>
            <Link to="/create-leave-rules" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i>Leave Rules
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-20 pd-sm-40">
              <div className="row">
                
                  <ReactDataTable cols={cols} receiveData={receiveData} isLoading={loading} actionTemplate={actionTemplate} deleteData={deleteData} />
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const actionTemplate = (rowData, deleteData) => {
  return (
    <div className="text-xs text-nowrap">
      <Link
        to={{
          pathname: "/edit-leave-rules/" + rowData.id,
          state: { id: rowData.id },
        }}
        className="btn btn-primary btn-sm"
        title="Edit"
      >
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
      <button className="btn btn-danger btn-sm ms-1" onClick={() => deleteData(rowData.id, "leave-rules", "Leave Rules")} title="Delete">
        <i className="fa fa-trash"></i>
      </button>
    </div>
  );
};

export default LeaveRules;
