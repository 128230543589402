import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

import useAlertFromLocation from "../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../hooks/useDeleteData";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const [receiveData, setReceiveData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/requisition");
      setReceiveData(res.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log(receiveData);

  // to delete data call
  const deleteData = useDeleteData(fetchData);
  // to show alert message
  const alert = useAlertFromLocation();

  const cols = [
    { field: "vacancy", header: "Vacancy" },
    { field: "department.name", header: "Department" },
    { field: "designation.name", header: "Designation" },
    { field: "experience_requirements", header: "Experience Requirement" },
    { field: "job_title", header: "Title" },
    { field: "job_context", header: "Context" },
    { field: "job_description", header: "Description" },
    { field: "employment_status", header: "Employment Status" },
    { field: "last_date_of_application", header: "Last Date of Application" },
    { field: "salary_range", header: "Salary Range" },
    { field: "key_skills", header: "Key Skills" },
    { field: "candidate_need_to_be_join_date", header: "Needed to be joining" },
    // { field: "created_at", header: "Created At" },
  ];

  return (
    <div>
      <IsLogin></IsLogin>
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Requisitions</h5>
            <Link to="/create-requisition" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i>Requisition
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-20 pd-sm-40">
              <div className="row">
                
                  <ReactDataTable
                    cols={cols}
                    receiveData={receiveData}
                    isLoading={loading}
                    actionTemplate={actionTemplate}
                    deleteData={deleteData}
                  />
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const actionTemplate = (rowData, deleteData) => {
  return (
    <div className="text-xs text-nowrap">
      <Link
        to={{
          pathname: "/edit-requisition/" + rowData.id,
          state: { id: rowData.id },
        }}
        className="btn btn-primary btn-sm"
        title="Edit"
      >
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
      <button
        className="btn btn-danger btn-sm ms-1"
        onClick={() => deleteData(rowData.id, "requisition", "Requisition")}
        title="Delete"
      >
        <i className="fa fa-trash"></i>
      </button>
    </div>
  );
};

export default List;
