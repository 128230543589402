import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

const Create = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [chartOfAccount, setChartOfAccount] = useState({});
  const [error, setError] = useState({});
  const [visible, setVisible] = useState(false);
  const { checkRequiredFields } = useRequiredFields();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const res = await Http.get("/chart-of-account-code-2");
        setChartOfAccount(res.data);
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    getCurrentDateInput();
  }, []);

  const handleCustomChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));

    if (value == "new") setVisible(true);
    else setVisible(false);
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const selectHandleChange = (selectedOption, actionMeta) => {
    const name = actionMeta.name;
    const value = selectedOption.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const displayError = (fieldName) => {
    if (error && error[fieldName]) {
      return <div className="text-danger small">{error[fieldName]}</div>;
    }
    return null;
  };

  const getCurrentDateInput = () => {
    const dateObj = new Date();

    // get the month in this format of 04, the same for months
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();

    const shortDate = `${year}-${month}-${day}`;

    setInputs((values) => ({ ...values, ["effective_date"]: shortDate }));
  };
  // Default Load
  // const fetchData = () => {
  //   Http.get("/chart-of-account-code-2").then((res) => {
  //     // setChartOfAccount(res.data.chart_of_accounts);
  //     setChartOfAccount(res.data);
  //   });
  // };
  // useEffect(() => {
  //   fetchData();
  //   getCurrentDateInput();
  // }, []);
  // End Default Load

  const formSubmit = () => {
    // if (checkRequiredFields()) {
    checkRequiredFields();
    Http.post("/sub-category", inputs)
      .then((res) => {
        navigate("/sub-category", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
    // }
  };

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Sub Category</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Create Sub Category</h6>
            {/* <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div> */}
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label htmlFor="">
                      Chart of Account <i className="fa fa-star required"></i>
                    </label>
                    {/* <select
                  className="form-control"
                  name="acc_chart_of_account_id"
                  onChange={handleChange}
                >
                  <option value="">Select Chart of Account</option>
                  {Array.from(chartOfAccount).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.code + " - " + item.name}
                    </option>
                  ))}
                </select> */}
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={chartOfAccount[0]}
                      name="acc_chart_of_account_id"
                      options={chartOfAccount}
                      onChange={selectHandleChange}
                      data-required
                    />

                    {displayError("acc_chart_of_account_id")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Name <i className="fa fa-star required"></i>
                    </label>
                    <select
                      className="form-control"
                      name="name"
                      onChange={handleCustomChange}
                      data-required
                    >
                      <option value="">Select Name</option>
                      <option value="hr">HR</option>
                      <option value="assets">Assets</option>
                      <option value="supplier">Supplier</option>
                      <option value="distributor">Distributor</option>
                      <option value="customer">Customer</option>
                      <option value="expense">Expense</option>
                      <option value="new">New</option>
                    </select>
                    {displayError("name")}
                  </div>
                  <div
                    className="col-lg"
                    style={{ display: visible ? "block" : "none" }}
                  >
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="new_name"
                      value={inputs.new_name || ""}
                      onChange={handleChange}
                    />
                    {displayError("new_name")}
                  </div>
                  <div
                    className="col-lg"
                    style={{ display: visible ? "block" : "none" }}
                  >
                    <label>Code</label>
                    <input
                      type="text"
                      className="form-control"
                      name="code"
                      value={inputs.code || ""}
                      onChange={handleChange}
                    />

                    {displayError("code")}
                  </div>
                  <div className="col-lg">
                    <label>Effective Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="effective_date"
                      value={inputs.effective_date || ""}
                      onChange={handleChange}
                    />

                    {displayError("effective_date")}
                  </div>
                  <div className="col-lg">
                    <label>Opening Balance</label>
                    <input
                      type="text"
                      className="form-control"
                      name="opening_balance"
                      value={inputs.opening_balance || 0}
                      onChange={handleChange}
                    />

                    {displayError("opening_balance")}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <textarea
                      className="form-control"
                      name="remarks"
                      value={inputs.remarks || ""}
                      onChange={handleChange}
                      placeholder="Remarks"
                    />

                    {displayError("remarks")}
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Create;
