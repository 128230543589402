import React from "react";
import { Link } from "react-router-dom";

const Accounts = ({ onMenuClick, active }) => {
  return (
    <>
      <li className="nav-item">
        <Link
          to
          className={`nav-link with-sub ${
            active === "/accounts" ||
            active === "/chart-of-account" ||
            active === "/budget-plan" ||
            active === "/journal-code" ||
            active === "/sub-category" ||
            active === "/journal-voucher" ||
            active === "/cash-count" ||
            active === "/setup"
              ? "active show-sub"
              : ""
          }`}
        >
          <i className="fa fa-briefcase" aria-hidden="true"></i>
          <span>Accounts</span>
        </Link>
        <ul className="nav-sub">
          <li className="nav-item">
            <Link to="/budget-plan" className={`nav-link ${active === "/budget-plan" ? "active" : ""}`} onClick={() => onMenuClick("/budget-plan")}>
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Budget Planning
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/chart-of-account"
              className={`nav-link ${active === "/chart-of-account" ? "active" : ""}`}
              onClick={() => onMenuClick("/chart-of-account")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Chart Of Accounts
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              to="/tree-chart-of-account"
              className={`nav-link ${
                active === "tree-chart-of-account" ? "active" : ""
              }`}
              onClick={() => setActive("tree-chart-of-account")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; TreeView Chart Of Accounts
            </Link>
          </li> */}
          <li className="nav-item">
            <Link
              to="/journal-code"
              className={`nav-link ${active === "/journal-code" ? "active" : ""}`}
              onClick={() => onMenuClick("/journal-code")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Journal Code
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/sub-category"
              className={`nav-link ${active === "/sub-category" ? "active" : ""}`}
              onClick={() => onMenuClick("/sub-category")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Sub Category
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/journal-voucher"
              className={`nav-link ${active === "/journal-voucher" ? "active" : ""}`}
              onClick={() => onMenuClick("/journal-voucher")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Journal Voucher
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/cash-count" className={`nav-link ${active === "/cash-count" ? "active" : ""}`} onClick={() => onMenuClick("/cash-count")}>
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Petty Cash
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/auto-journal-setup"
              className={`nav-link ${active === "/auto-journal-setup" ? "active" : ""}`}
              onClick={() => onMenuClick("/auto-journal-setup")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Setup
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/transactions-list-connect"
              className={`nav-link ${active === "/transactions-list-connect" ? "active" : ""}`}
              onClick={() => onMenuClick("/transactions-list-connect")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Transactions List Connect
            </Link>
          </li>
        </ul>
      </li>
    </>
  );
};

export default Accounts;
