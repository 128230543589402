import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

const CreateRegion = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [staff, setStaff] = useState({});
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  const [list, setList] = useState([{ desc: "", date: "" }]);
  const [loading, setLoading] = useState(false);
  const { checkRequiredFields } = useRequiredFields();

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const res = await Http.get("/staff2");
        setStaff(res.data);
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // const fetchData = () => {
  //   Http.get("/staff2").then((res) => {
  //     setStaff(res.data);
  //   });
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const inputInfo = [...list];

    inputInfo[index][name] = value;
    setList(inputInfo);
  };

  const selectHandleChange = (selectedValues) => {
    setSelectedStaff(selectedValues);
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // Add More List
  const addMoreList = () => {
    setList([...list, { date: "", desc: "" }]); // Add One More List
  };

  // Remove List
  const removeList = (index) => {
    // Load All Existing experience State Data
    const inputInfo = [...list];
    // Remove Experience by Index/Click Event Wise
    inputInfo.splice(index, 1);
    // Replace Existing experience into State
    setList(inputInfo);
  };

  const formSubmit = () => {
    if (selectedStaff.length == 0) setIsRequired(true);
    if (checkRequiredFields()) {
      // inputs.list = list;
      // inputs.staff_list = selectedStaff;
      Http.post("/roaster-holiday", { ...inputs, list, staff_list: selectedStaff })
        .then((res) => {
          navigate("/roaster-holiday-list", {
            state: { message: res.data.message, type: "success" },
          });
        })
        .catch(function (e) {
          setError(e.response.data.errors);
        });
    }
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Roaster Holiday Calander</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Create Roaster Holiday</h6>

            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <FormValidation error={error} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <label className="font-weight-bold">
                      Name <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={inputs.name || ""}
                      onChange={handleChange}
                      placeholder="Input Name"
                      data-required
                    />
                  </div>
                  {/* col */}
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label className="font-weight-bold">
                      Year <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="year"
                      value={inputs.year || ""}
                      onChange={handleChange}
                      placeholder="Input Year"
                      data-required
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label className="font-weight-bold">
                      Staff <i className="fa fa-star required"></i>
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={staff[0]}
                      name="hr_staff_basic_info_id"
                      options={staff}
                      text="Please select user."
                      isMulti="yes"
                      onChange={selectHandleChange}
                      {...(isRequired && {
                        styles: {
                          control: (base, { menuIsOpen, hasValue }) => {
                            const borderColor = hasValue ? "#ccc" : menuIsOpen ? "#ccc" : "red";
                            return { ...base, borderColor };
                          },
                        },
                      })}
                    />
                  </div>
                </div>

                {/* <div className="row mt-3">
                  <div className="col-12">
                    <div className="col-lg">
                      <label>Name</label>
                      <input type="text" className="form-control" name="name" value={inputs.name || ""} onChange={handleChange} />
                    </div>
                    <div className="col-lg mt-3">
                      <label>Year</label>
                      <input type="number" className="form-control" name="year" value={inputs.year || ""} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-10"></div>
                  <div className="col-2 mt-4">
                    <button className="btn btn-info btn-block mg-b-10" onClick={addMoreList}>
                      <i className="fa fa-plus mg-r-10"></i> ADD More
                    </button>
                  </div>
                </div> */}
                {Array.from(list).map((x, i) => (
                  <div className="row mt-3" key={i}>
                    <div className="col-lg">
                      <div className="row">
                        <div className="col-6">
                          <label className="font-weight-bold">Date</label>
                          <input
                            type="date"
                            className="form-control"
                            name="date"
                            value={x.date || ""}
                            onChange={(e) => handleInputChange(e, i)}
                            data-required
                          />
                        </div>
                        <div className="col-5">
                          <label className="font-weight-bold">Remarks</label>
                          <input
                            type="text"
                            className="form-control"
                            name="desc"
                            value={x.desc || ""}
                            onChange={(e) => handleInputChange(e, i)}
                            data-required
                          />
                        </div>
                        <div className="col-1 mt-4 pt-2">
                          {list.length > 1 ? (
                            <button className="btn btn-danger btn-sm" onClick={() => removeList(i)}>
                              <i className="fa fa-trash"></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* <div className="row mt-3">
                  <div className="col-lg">
                    <textarea className="form-control" name="remarks" value={inputs.remarks || ""} onChange={handleChange} placeholder="Remarks" />
                  </div>
                </div> */}

                <div className="row mt-5">
                  <div className="col-6 text-right">
                    <button className="btn btn-primary mg-b-10" onClick={formSubmit} style={{ width: "50%" }}>
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                  <div className="col-6 ">
                    <button className="btn btn-info mg-b-10" onClick={addMoreList} style={{ width: "50%" }}>
                      <i className="fa fa-plus mg-r-10"></i> ADD More
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default CreateRegion;
