import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
// import { ProgressSpinner } from "primereact/progressspinner";

const Create = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    date: "",
    thousand: 0,
    five_hundreed: 0,
    two_hundreed: 0,
    hundreed: 0,
    fifty: 0,
    tweenty: 0,
    ten: 0,
    five: 0,
    two: 0,
    one: 0,
    suspense: 0,
    total_amount: 0,
  });
  const [staff, setStaff] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [error, setError] = useState({});

  const getCurrentDateInput = () => {
    const dateObj = new Date();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();
    const shortDate = `${year}-${month}-${day}`;
    setInputs((values) => ({ ...values, ["date"]: shortDate }));
  };

  useEffect(() => {
    getCurrentDateInput();
  }, []);

  useEffect(() => {
    console.log("inputs", inputs);
    console.log("Object.keys(inputs)", Object.keys(inputs));

    const newTotalAmount = Object.keys(inputs).reduce((acc, key) => {
      console.log("inputs[key]", inputs[key]);
      if (key.includes("_total") && !isNaN(inputs[key])) {
        return acc + parseFloat(inputs[key]);
      }
      return acc;
    }, 0);

    setTotalAmount(newTotalAmount - parseFloat(inputs.suspense));
  }, [inputs]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const { amount, total } = e.target.dataset;

    // Update corresponding total
    const newValue = parseFloat(value) || 0;
    const newTotal = newValue * (parseFloat(amount) || 0);

    setInputs((prev) => ({
      ...prev,
      [name]: value,
      [total]: newTotal,
    }));
  };

  const formSubmit = () => {
    inputs.total_amount = totalAmount;
    Http.post("/cash-count", inputs)
      .then((res) => {
        navigate("/cash-count", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Cash Count</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} amount="" value={inputs.date || ""} onChange={handleChange} />
              </div>
            </div>
            <div className="row">
              <div className="col-lg">
                <label>
                  Date <i className="fa fa-star required"></i>
                </label>
                <input type="date" className="form-control" name="date" value={inputs.date || ""} onChange={handleChange} />
              </div>
            </div>
            <div className="row mt-3">
              <div className="table-responsive p-0 pb-2">
                <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 ">
                  <tbody>
                    <tr>
                      <th colSpan={5}>
                        <h5 className="categoryName text-center" style={{ fontWeight: "bold" }}>
                          Category Name
                        </h5>
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="number"
                          name="thousand"
                          className="form-control thousand"
                          data-amount="1000"
                          data-total="thousand_total"
                          value={inputs.thousand || ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>X</td>
                      <td className="amount">1000.00</td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>=</td>
                      <td>
                        <input
                          type="number"
                          name="thousand_total"
                          className="form-control thousand_total totalCount"
                          step="any"
                          readOnly="readonly"
                          data-amount=""
                          value={inputs.thousand_total || ""}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="number"
                          name="five_hundreed"
                          className="form-control five_hundreed"
                          data-amount="500"
                          data-total="five_hundred_total"
                          value={inputs.five_hundreed || ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>X</td>
                      <td className="amount">500.00</td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>=</td>
                      <td>
                        <input
                          type="number"
                          name="five_hundred_total"
                          className="form-control five_hundred_total totalCount"
                          step="any"
                          readOnly="readonly"
                          data-amount=""
                          value={inputs.five_hundred_total || ""}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="number"
                          name="two_hundreed"
                          className="form-control two_hundreed"
                          data-amount="200"
                          data-total="two_hundred_total"
                          value={inputs.two_hundreed || ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>X</td>
                      <td className="amount">200.00</td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>=</td>
                      <td>
                        <input
                          type="number"
                          name="two_hundred_total"
                          className="form-control two_hundred_total totalCount"
                          step="any"
                          readOnly="readonly"
                          data-amount=""
                          value={inputs.two_hundred_total || ""}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="number"
                          name="hundreed"
                          className="form-control hundreed"
                          data-amount="100"
                          data-total="one_hundred_total"
                          value={inputs.hundreed || ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>X</td>
                      <td className="amount">100.00</td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>=</td>
                      <td>
                        <input
                          type="number"
                          name="one_hundred_total"
                          className="form-control one_hundred_total totalCount"
                          step="any"
                          readOnly="readonly"
                          data-amount=""
                          value={inputs.one_hundred_total || ""}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="number"
                          name="fifty"
                          className="form-control fifty"
                          data-amount="50"
                          data-total="fifty_total"
                          value={inputs.fifty || ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>X</td>
                      <td className="amount">50.00</td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>=</td>
                      <td>
                        <input
                          type="number"
                          name="fifty_total"
                          className="form-control fifty_total totalCount"
                          step="any"
                          readOnly="readonly"
                          data-amount=""
                          value={inputs.fifty_total || ""}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="number"
                          name="tweenty"
                          className="form-control tweenty"
                          data-amount="20"
                          data-total="twenty_total"
                          value={inputs.tweenty || ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>X</td>
                      <td className="amount">20.00</td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>=</td>
                      <td>
                        <input
                          type="number"
                          name="twenty_total"
                          className="form-control twenty_total totalCount"
                          step="any"
                          readOnly="readonly"
                          data-amount=""
                          value={inputs.twenty_total || ""}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="number"
                          name="ten"
                          className="form-control ten"
                          data-amount="10"
                          data-total="ten_total"
                          value={inputs.ten || ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>X</td>
                      <td className="amount">10.00</td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>=</td>
                      <td>
                        <input
                          type="number"
                          name="ten_total"
                          className="form-control ten_total totalCount"
                          step="any"
                          readOnly="readonly"
                          data-amount=""
                          value={inputs.ten_total || ""}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="number"
                          name="five"
                          className="form-control five"
                          data-amount="5"
                          data-total="five_total"
                          value={inputs.five || ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>X</td>
                      <td className="amount">5.00</td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>=</td>
                      <td>
                        <input
                          type="number"
                          name="five_total"
                          className="form-control five_total totalCount"
                          step="any"
                          readOnly="readonly"
                          data-amount=""
                          value={inputs.five_total || ""}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="number"
                          name="two"
                          className="form-control two"
                          data-amount="2"
                          data-total="two_total"
                          value={inputs.two || ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>X</td>
                      <td className="amount">2.00</td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>=</td>
                      <td>
                        <input
                          type="number"
                          name="two_total"
                          className="form-control two_total totalCount"
                          step="any"
                          readOnly="readonly"
                          data-amount=""
                          value={inputs.two_total || ""}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="number"
                          name="one"
                          className="form-control one"
                          data-amount="1"
                          data-total="one_total"
                          value={inputs.one || ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>X</td>
                      <td className="amount">1.00</td>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>=</td>
                      <td>
                        <input
                          type="number"
                          name="one_total"
                          className="form-control one_total totalCount"
                          step="any"
                          readOnly="readonly"
                          data-amount=""
                          value={inputs.one_total || ""}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4" style={{ textAlign: "right" }}>
                        Suspense.
                      </td>
                      <td>
                        <input type="text" name="suspense" className="form-control suspense" value={inputs.suspense || ""} onChange={handleChange} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4" style={{ textAlign: "right" }}>
                        Total.
                      </td>
                      <td>
                        <input
                          type="text"
                          name="total_amount"
                          className="form-control total_amount"
                          readOnly="readonly"
                          data-amount=""
                          value={totalAmount || 0}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <button className="btn btn-primary btn-block mg-b-10" onClick={formSubmit}>
                  <i className="fa fa-send mg-r-10"></i> Create
                </button>
              </div>
            </div>
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Create;
