import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

import useAlertFromLocation from "../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../hooks/useDeleteData";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const [receiveData, setReceiveData] = useState([]);
  const [url, setUrl] = useState(Http.getUri());
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/candidate");
      setReceiveData(res.data);

      const splitUrl = url.split("/");
      let fullUrl = "";
      for (var i = 0; i < splitUrl.length - 1; i++) {
        fullUrl += splitUrl[i] + "/";
      }
      setUrl(fullUrl);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // to delete data call
  const deleteData = useDeleteData(fetchData);
  // to show alert message
  const alert = useAlertFromLocation();

  const cols = [
    { field: "name", header: "Name" },
    { field: "email", header: "Email" },
    { field: "mobile", header: "Mobile" },
    { field: "experience", header: "Experience" },
    { field: "skills", header: "Skills" },
    { field: "expected_salary", header: "Expected Salary" },
    { field: "expected_joining_date", header: "Expected Joining Date" },
    { field: "reference_name", header: "Reference" },
    {
      field: (item) =>
        item.cv ? (
          <Link to={url + "cv/" + item.cv} target="_blank" rel="noreferrer">
            CV Download
          </Link>
        ) : (
          ""
        ),
      header: "CV",
    },
    { field: "status", header: " Status " },
    { field: "remarks", header: "Remarks" },
    {
      field: (item) => (
        <>
          {item.invite == 0 && item.approved_by == "" ? (
            <button className="btn btn-info btn-sm ms-1" onClick={() => invite(item.id, 1)}>
              <i className="fa fa-check"></i>
            </button>
          ) : (
            ""
          )}

          {item.invite == 1 && item.approved_by == "" ? (
            <button className="btn btn-primary btn-sm ms-1" onClick={() => invite(item.id, 0)}>
              <i className="fa fa-minus"></i>
            </button>
          ) : (
            ""
          )}
        </>
      ),
      header: "Approvals",
    },
  ];

  // const deleteData = (id, deleteApiUrl, showMsg) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       Http.delete("/" + deleteApiUrl + "/" + id).then((res) => {
  //         setReceiveData(res.data);
  //         Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
  //       });
  //     }
  //   });
  // };

  const invite = (id, status) => {
    Http.get("/candidate_invite_change/" + status + "/" + id).then((res) => {
      setReceiveData(res.data);
      Swal.fire("Candiate Invitation!", "Candidate Invitation Has Been Updated", "success");
    });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Candidate</h5>
            <Link to="/create-candidate" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i>Candidate
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-20 pd-sm-40">
              <div className="row">
                {/* <div className="table-responsive p-0 pb-2">
                  <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 datatable">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">S/N</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Name</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Email</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Mobile</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Experience</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Skills</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Expected Salary</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Expected Joining Date</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Reference</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">CV</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Status</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Remarks</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {receiveData.map((item, index) => (
                        <tr key={item.id}>
                          <td className="text-xs">{index + 1}</td>
                          <td className="text-xs">{item.name}</td>
                          <td className="text-xs">{item.email}</td>
                          <td className="text-xs">{item.mobile}</td>
                          <td className="text-xs">{item.experience}</td>
                          <td className="text-xs">{item.skills}</td>
                          <td className="text-xs">{item.expected_salary}</td>
                          <td className="text-xs">{item.expected_joining_date}</td>
                          <td className="text-xs">{item.reference_name}</td>
                          <td className="text-xs">
                            {item.cv ? (
                              <a href={url + "cv/" + item.cv} target="_blank" rel="noreferrer">
                                CV Download
                              </a>
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="text-xs">{item.status}</td>
                          <td className="text-xs">{item.remarks}</td>
                          <td className="text-xs">
                            {item.invite == 0 && item.approved_by == "" ? (
                              <button className="btn btn-info btn-sm ms-1" onClick={() => invite(item.id, 1)}>
                                <i className="fa fa-check"></i>
                              </button>
                            ) : (
                              ""
                            )}
                            {item.invite == 1 && item.approved_by == "" ? (
                              <button className="btn btn-primary btn-sm ms-1" onClick={() => invite(item.id, 0)}>
                                <i className="fa fa-minus"></i>
                              </button>
                            ) : (
                              ""
                            )}
                            {item.approved_by < 1 ? (
                              <Link
                                to={{
                                  pathname: "/create-staff/" + item.id,
                                }}
                                className="btn btn-info btn-sm ms-1"
                              >
                                <i className="fa fa-list" aria-hidden="true"></i>
                              </Link>
                            ) : (
                              ""
                            )}
                            {item.approved_by < 1 ? (
                              <button className="btn btn-danger btn-sm ms-1" onClick={() => deleteData(item.id, "candidate", "Candidate")}>
                                <i className="fa fa-trash"></i>
                              </button>
                            ) : (
                              "Approved"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div> */}
                <ReactDataTable cols={cols} receiveData={receiveData} isLoading={loading} actionTemplate={actionTemplate} deleteData={deleteData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const actionTemplate = (rowData, deleteData) => {
  return (
    <div className="text-xs text-nowrap">
      {/* Edit Candidate */}
      <Link
        to={{
          pathname: "/edit-candidate/" + rowData.id,
          state: { id: rowData.id },
        }}
        className="btn btn-primary btn-sm"
        title="Edit"
      >
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>

      {/* Create Staff from Candidate */}
      {rowData.approved_by < 1 ? (
        <Link to={{ pathname: "/create-staff/" + rowData.id }} className="btn btn-info btn-sm ms-1">
          <i className="fa fa-plus" aria-hidden="true"></i>
        </Link>
      ) : (
        ""
      )}

      {/* Delete Candidate */}
      {rowData.approved_by < 1 ? (
        <button className="btn btn-danger btn-sm ms-1" onClick={() => deleteData(rowData.id, "candidate", "Candidate")} title="Delete">
          <i className="fa fa-trash"></i>
        </button>
      ) : (
        "Approved"
      )}
    </div>
  );
};

export default List;
