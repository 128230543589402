import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import { LoaderComponent } from "../../../Common/LoaderComponent";
import { ProgressSpinner } from "primereact/progressspinner";

const Return = (props) => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const { id } = useParams();

  const [asset, setAsset] = useState({});
  const [staff, setStaff] = useState({});
  const [location, setLocation] = useState({});
  const [factory, setFactory] = useState({});
  const [department, setDepartment] = useState({});

  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  
useEffect(() => {
  setLoading(true);

  const fetchData = async () => {
    try {
      const res = await Http.get("/asset-assign/" + id + "/edit");
      setInputs({
        return_date: res.data.return_date,
        assign_date: res.data.assign_date,
        asset_id: res.data.asset_id,
        hr_staff_basic_info_id: res.data.hr_staff_basic_info_id,
        location_id: res.data.location_id,
        factory_id: res.data.factory_id,
        department_id: res.data.department_id,
        remarks: res.data.remarks,
      });

      const res2 = await Http.get("/asset");
      setAsset(res2.data);

      const res3 = await Http.get("/staff");
      setStaff(res3.data);

      const res4 = await Http.get("/location");
      setLocation(res4.data);

      const res5 = await Http.get("/factory");
      setFactory(res5.data);

      const res6 = await Http.get("/department");
      setDepartment(res6.data);


    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };
  fetchData();
}, []);

  // const fetchData = () => {
  //   Http.get("/asset-assign/" + id + "/edit").then((res) => {
  //     setInputs({
  //       return_date: res.data.return_date,
  //       assign_date: res.data.assign_date,
  //       asset_id: res.data.asset_id,
  //       hr_staff_basic_info_id: res.data.hr_staff_basic_info_id,
  //       location_id: res.data.location_id,
  //       factory_id: res.data.factory_id,
  //       department_id: res.data.department_id,
  //       remarks: res.data.remarks,
  //     });
  //   });
  //   Http.get("/asset").then((res) => {
  //     setAsset(res.data);
  //   });
  //   Http.get("/staff").then((res) => {
  //     setStaff(res.data);
  //   });
  //   Http.get("/location").then((res) => {
  //     setLocation(res.data);
  //   });
  //   Http.get("/factory").then((res) => {
  //     setFactory(res.data);
  //   });
  //   Http.get("/department").then((res) => {
  //     setDepartment(res.data);
  //   });
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const formSubmit = () => {
    Http.put("/asset-assign/" + id, inputs)
      .then((res) => {
        navigate("/asset-assign", {
          state: { message: res.data.message, type: res.data.type },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Return Asset Assign</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Return Asset Assign</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            <div className="row">
              <div className="col-lg">
                <label>
                  Description <i className="fa fa-star required"></i>
                </label>
                <textarea className="form-control" name="remarks" value={inputs.remarks || ""} onChange={handleChange}></textarea>
              </div>
              <div className="col-lg">
                <label>
                  Return Date <i className="fa fa-star required"></i>
                </label>
                <input type="date" className="form-control" name="return_date" value={inputs.return_date || ""} onChange={handleChange} />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <button className="btn btn-primary btn-block mg-b-10" onClick={formSubmit}>
                  <i className="fa fa-send mg-r-10"></i> Update
                </button>
              </div>
              <div className="col-6"></div>
              <div className="col-3"></div>
            </div>
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Return;
