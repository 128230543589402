import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Alert from "../../../../Common/Alert";
import DatatableFunc from "../../../../Common/DatatableFunc";
import Http from "../../../../Common/Http";
import IsLogin from "../../../../Common/IsLogin";
import ReactDataTable from "../../../../Common/ReactDataTable";

const List = () => {
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true); // Start loading

    try {
      const res = await Http.get(`/target`);
      setReceiveData(res.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false); // Stop loading regardless of the outcome
    }
  };

  const cols = [
    { field: "target.target_month", header: "Month" },
    { field: "target.target_start_date", header: "Start Date" },
    { field: "target.target_end_date", header: "End Date" },
    { field: "target.no_of_working_days", header: "No Of Working Days" },
    {
      // field: "qty",
      header: "Target",
      field: (item) => parseFloat(item.qty).toFixed(2),
    },
    {
      // field: "orderIms.qty",
      header: "Total IMS",
      field: (item) =>
        item.orderIms.qty !== null
          ? parseFloat(item.orderIms.qty).toFixed(2)
          : " --",
    },
    {
      // field: "difference",
      header: "Difference",
      field: (item) => {
        // const qty = parseFloat(item.qty);
        // const orderImsQty = parseFloat(item.orderIms.qty);
        const difference = parseFloat(item.qty) - parseFloat(item.orderIms.qty);
        return isNaN(difference) ? " --" : difference.toFixed(2);
      },
    },
  ];

  const actionTemplate = (item, deleteData) => {
    return (
      <td className="text-xs">
        <Link
          to="/monthly-target-view"
          className="btn btn-primary m-0 p-1 px-2"
        >
          <i className="fa fa-bullseye"></i>
          {/* <i class="fa-solid fa-bullseye"></i> */}
        </Link>
      </td>
    );
  };

  // const fetchData = () => {
  //   Http.get("/target").then((res) => {
  //     setReceiveData(res.data);
  //   });
  // };

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">Monthly Target</h5>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-sm-40">
              <h3 className="text-center">Monthly Target</h3>
              <div className="row">
                <div className="col-1"></div>
                <div className="col-2">
                  <Link
                    to="/create-target"
                    className="btn btn-primary btn-block"
                  >
                    <i className="fa fa-plus-square"></i> Add Target
                  </Link>
                </div>
                <div className="col-2">
                  <Link to="/edit-target" className="btn btn-info btn-block">
                    <i className="fa fa-plus-square"></i> Edit Target
                  </Link>
                </div>
                <div className="col-2">
                  <Link
                    to="/opening-stock-view"
                    className="btn btn-success btn-block"
                  >
                    <i className="fa fa-plus-square"></i> View OS
                  </Link>
                </div>
                <div className="col-2">
                  <Link
                    to="/create-opening-stock"
                    className="btn btn-warning btn-block"
                  >
                    <i className="fa fa-plus-square"></i> Add OS
                  </Link>
                </div>
                <div className="col-2">
                  <Link
                    to="/edit-opening-stock"
                    className="btn btn-danger btn-block"
                  >
                    <i className="fa fa-plus-square"></i> Edit OS
                  </Link>
                </div>
                <div className="col-1"></div>
              </div>
            </div>
          </div>
          <div className="am-pagebody">
            <div className="card pd-20 pd-sm-40">
              {/* <h6 className="card-body-title mg-sm-b-30">Datatable</h6> */}
              {/* <div className="row">
                <div className="table-responsive p-0 pb-2">
                  <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 datatable">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          S/N
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Month
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Start Date
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          End Date
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          No Of Working Days
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Target
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Total IMS
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Difference
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {receiveData.map((item, index) => (
                        <tr key={item.id}>
                          <td className="text-xs">{index + 1}</td>
                          <td className="text-xs">
                            <Link to="/monthly-target-view">
                              {item.target ? item.target.target_month : ""}
                            </Link>
                          </td>
                          <td className="text-xs">
                            {item.target ? item.target.target_start_date : ""}
                          </td>
                          <td className="text-xs">
                            {item.target ? item.target.target_end_date : ""}
                          </td>
                          <td className="text-xs">
                            {item.target ? item.target.no_of_working_days : ""}
                          </td>
                          <td className="text-xs">
                            {parseFloat(item.qty).toFixed(2)}
                          </td>
                          <td className="text-xs">
                            {item.orderIms
                              ? parseFloat(item.orderIms.qty).toFixed(2)
                              : 0}
                          </td>
                          <td className="text-xs">
                            {(
                              parseFloat(item.qty) -
                              parseFloat(item.orderIms.qty)
                            ).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div> */}
              <ReactDataTable
                cols={cols}
                receiveData={receiveData}
                isLoading={loading}
                actionTemplate={actionTemplate}
                // deleteData={deleteData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
