import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [staff, setStaff] = useState({});
  const [authData, setAuthData] = useState([]);
  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    month: ("0" + (date.getMonth() + 1)).slice(-2),
    year: year,
    status: "active",
    from: "",
    to: "",
    hr_staff_basic_info_id: "",
  });

  const fetchData = async () => {
    Http.get("/login-staff-detail").then((res) => {
      setAuthData(res.data);
    });
    Http.get("/staff2").then((res) => {
      setStaff(res.data);
    });
    setLoading(true);
    try {
      const response = await Http.post("/monthly-team-summary", inputs);
      setReceiveData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  const selectHandleChange = (selectedOption, actionMeta) => {
    const name = actionMeta.name;
    const value = selectedOption.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const formSubmit = async () => {
    setLoading(true); // Start loading
    try {
      const response = await Http.post("/monthly-team-summary", inputs);
      setReceiveData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  // Define columns
  const cols = [
    { field: "name", header: "Name" },
    { field: "total_working_date", header: "Working Days" },
    { field: "number_of_attendance", header: "Attendance" },
    { field: "number_of_attendance_late", header: "Total Late Present" },
    { field: "total_working_hour", header: "Total Working Hour" },
    { field: "total_worked", header: "Total Worked" },
  ];

  return (
    <div>
      <IsLogin />
      {/* <LoaderComponent /> */}
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">Monthly Team Summary</h5>
          </div>
          <div className="am-pagebody">
            <Alert alert={alert} />
            <div className="card pd-sm-40">
              <div className="row">
                {(authData.user_type === "admin" ||
                  authData.user_type === "hr" ||
                  authData.approved_by > 0 || authData.department_head > 0) && (
                  <div className="col-lg">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      name="hr_staff_basic_info_id"
                      options={staff}
                      onChange={selectHandleChange}
                    />
                  </div>
                )}
                {/* <div className="col-lg">
                  <input
                    type="date"
                    className="form-control"
                    name="from"
                    value={inputs.from || ""}
                    onChange={handleChange}
                    placeholder="From Date"
                  />
                </div>
                <div className="col-lg">
                  <input
                    type="date"
                    className="form-control"
                    name="to"
                    value={inputs.to || ""}
                    onChange={handleChange}
                    placeholder="To Date"
                  />
                </div> */}
                <div className="col-lg">
                  <button
                    className="btn btn-primary btn-block mg-b-10"
                    onClick={formSubmit}
                  >
                    <i className="fa fa-send mg-r-10"></i> Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="am-pagebody">
            <div className="card pd-20 pd-sm-40">
              <ReactDataTable
                cols={cols}
                receiveData={receiveData}
                isLoading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
