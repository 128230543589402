import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Alert from "../../../Common/Alert";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true); // Start loading

    try {
      const res = await Http.get(`/asset-assign`);
      setReceiveData(res.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false); // Stop loading regardless of the outcome
    }
  };

  const cols = [
    { field: "asset.name", header: "Asset Name" },
    { field: "factory.name", header: "Factory" },
    { field: "location.name", header: "Location" },
    { field: "department.name", header: "Department" },
    { field: "staff.name", header: "Staff" },
    { field: "assign_date", header: "Assign Date" },
    { field: "return_date", header: "Return Date" },
    { field: "remarks", header: "Remarks" },
    { field: "created_by.name", header: "Assigned By" },
    { field: "status", header: " Status" },
  ];

  const actionTemplate = (item, deleteData) => {
    return (
      <td className="text-xs">
        <Link
          to={{
            pathname: "/edit-asset-assign/" + item.id,
          }}
          className="btn btn-primary btn-sm"
        >
          <i className="fa fa-pencil" aria-hidden="true"></i>
        </Link>
        <Link
          to={{
            pathname: "/return-asset-assign/" + item.id,
          }}
          className="btn btn-info btn-sm ms-1"
        >
          <i className="fa fa-list" aria-hidden="true"></i>
        </Link>
        <Link
          to={{
            pathname: "/damage-asset-claim/" + item.id,
          }}
          className="btn btn-warning btn-sm ms-1"
        >
          <i className="fa fa-circle"></i>
        </Link>
        <button
          className="btn btn-danger btn-sm ms-1"
          onClick={() =>
            deleteData(item.id, "asset-category", "asset-category")
          }
        >
          <i className="fa fa-trash"></i>
        </button>
      </td>
    );
  };

  // const fetchData = () => {
  //   Http.get("/asset-assign").then((res) => {
  //     setReceiveData(res.data);
  //   });
  // };

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  const deleteData = (id, deleteApiUrl, showMsg) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Http.delete("/" + deleteApiUrl + "/" + id).then((res) => {
          setReceiveData(res.data);
          Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
        });
      }
    });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Assign List</h5>
            <Link to="/create-asset-assign" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i>new
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-20 pd-sm-40">
              {/* <h6 className="card-body-title mg-sm-b-30">Datatable</h6> */}
              {/* <div className="row">
                <div className="table-responsive p-0 pb-2">
                  <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 datatable">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          S/N
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Asset Name
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Factory
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Location
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Department
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Staff
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Assign Date
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Return Date
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Remarks
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Assigned By
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Status
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {receiveData.map((item, index) => (
                        <tr key={item.id}>
                          <td className="text-xs">{index + 1}</td>
                          <td className="text-xs">
                            {item.asset ? item.asset.name : ""}
                          </td>
                          <td className="text-xs">
                            {item.factory ? item.factory.name : ""}
                          </td>
                          <td className="text-xs">
                            {item.location ? item.location.name : ""}
                          </td>
                          <td className="text-xs">
                            {item.department ? item.department.name : ""}
                          </td>
                          <td className="text-xs">
                            {item.staff ? item.staff.name : ""}
                          </td>
                          <td className="text-xs">{item.assign_date}</td>
                          <td className="text-xs">{item.return_date}</td>
                          <td className="text-xs">{item.remarks}</td>
                          <td className="text-xs">
                            {item.created_by ? item.created_by.name : ""}
                          </td>
                          <td className="text-xs">{item.status}</td>
                          <td className="text-xs">
                            <Link
                              to={{
                                pathname: "/edit-asset-assign/" + item.id,
                              }}
                              className="btn btn-primary btn-sm"
                            >
                              <i
                                className="fa fa-pencil"
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link
                              to={{
                                pathname: "/return-asset-assign/" + item.id,
                              }}
                              className="btn btn-info btn-sm ms-1"
                            >
                              <i className="fa fa-list" aria-hidden="true"></i>
                            </Link>
                            <Link
                              to={{
                                pathname: "/damage-asset-claim/" + item.id,
                              }}
                              className="btn btn-warning btn-sm ms-1"
                            >
                              <i className="fa fa-circle"></i>
                            </Link>
                            <button
                              className="btn btn-danger btn-sm ms-1"
                              onClick={() =>
                                deleteData(
                                  item.id,
                                  "asset-category",
                                  "asset-category"
                                )
                              }
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div> */}
              <ReactDataTable
                cols={cols}
                receiveData={receiveData}
                isLoading={loading}
                actionTemplate={actionTemplate}
                deleteData={deleteData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
