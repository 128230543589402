import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";

const ReactDropdown = ({ options, optitle, onChange, val, placeholder }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  // const placeholder = placeholder ? placeholder : "Select";

  const selectedTemplate = (option) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option[optitle]}</div>
        </div>
      );
    }
    return <span> {placeholder ? placeholder : "Select"} </span>;
  };

  const optionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option[optitle]}</div>
      </div>
    );
  };

  const handleChange = (e) => {
    setSelectedOption(e.value);
    onChange(e); // call the onChange prop to update parent component state
  };

  const renderVal = val ? val : selectedOption;

  return (
    <div className="flex justify-content-center">
      <Dropdown
        value={renderVal}
        onChange={handleChange}
        options={options}
        optionLabel={optitle}
        placeholder="Select"
        filter
        valueTemplate={selectedTemplate}
        itemTemplate={optionTemplate}
        className="md:w-12rem drop"
      />
    </div>
  );
};

export default ReactDropdown;
