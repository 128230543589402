import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

import useAlertFromLocation from "../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../hooks/useDeleteData";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const [receiveData, setReceiveData] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/designation");
      setReceiveData(res.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  // to fetch data from the server
  useEffect(() => {
    fetchData();
  }, []);

  // to delete data call
  const deleteData = useDeleteData(fetchData);
  // to show alert message
  const alert = useAlertFromLocation();

  // columns for the datatable
  const cols = [
    { field: "name", header: "Name" },
    { field: "designation_code", header: "Code" },
  ];

  // action buttons for the datatable
  const actionTemplate = (rowData, deleteData) => {
    return (
      <div className="text-xs text-nowrap">
        <Link
          to={{
            pathname: "/editdesignation/" + rowData.id,
            state: { id: rowData.id },
          }}
          className="btn btn-primary btn-sm"
          title="Edit"
        >
          <i className="fa fa-pencil" aria-hidden="true"></i>
        </Link>
        <button className="btn btn-danger btn-sm ms-1" onClick={() => deleteData(rowData.id, "deleteDesignation", "Designation")} title="Delete">
          <i className="fa fa-trash"></i>
        </button>
      </div>
    );
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Designations</h5>
            <Link to="/createdesignation" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i>Designation
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-20 pd-sm-40">
              <div className="row">
                
                  <ReactDataTable cols={cols} receiveData={receiveData} isLoading={loading} actionTemplate={actionTemplate} deleteData={deleteData} />
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
