import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import Alert from "../../../Common/Alert";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
import { LoaderComponent } from "../../../Common/LoaderComponent";
import IsLogin from "../../../Common/IsLogin";

const Show = () => {
  const [receiveData, setReceiveData] = useState([]);
  const { id } = useParams();
  const [alert, setAlert] = useState(null);
  const location = useLocation();

  const fetchData = () => {
    Http.get("/issue/" + id).then((res) => {
      setReceiveData(res.data.info);
    });
  };

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">Issue Production</h5>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />
            <div className="card pd-20 pd-sm-40">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 padding">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-sm-12">
                        <h5 className="mb-3">Issue Production:</h5>
                        <div>Date :{receiveData.date}</div>
                        <div>Reference No :{receiveData.reference_no}</div>
                        <div>Time :{receiveData.time}</div>
                        <div>
                          Finished Good :
                          {receiveData.product ? receiveData.product.name : ""}
                        </div>
                        <div>Batch No :{receiveData.batch_no}</div>
                        <div>Batch Size :{receiveData.batch_size}</div>
                        <div>Receipe Type :{receiveData.receipe_type}</div>
                        <div>
                          Created By :
                          {receiveData.created_by
                            ? receiveData.created_by.name
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive-sm">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="center">#</th>
                            <th>Product</th>
                            <th className="text-right">Stndrd Qty</th>
                            <th className="text-right">Issue Qty</th>
                            <th className="text-right">Detail</th>
                          </tr>
                        </thead>
                        <tbody>
                          {receiveData.items
                            ? receiveData.items.map((item, index) => (
                                <tr key={item.id}>
                                  <td className="center">{index + 1}</td>
                                  <td className="left strong">
                                    {item.product ? item.product.name : ""}
                                  </td>
                                  <td className="text-right">
                                    {item.stndrd_qty}
                                  </td>
                                  <td className="text-right">{item.qty}</td>
                                  <td className="text-right">
                                    {item.detail
                                      ? item.detail.map(
                                          (value, key) =>
                                            "(" +
                                            value.qty +
                                            " X " +
                                            (value.grn
                                              ? value.grn.unit_price
                                              : 0) +
                                            ")" +
                                            "=" +
                                            value.qty *
                                              (value.grn
                                                ? value.grn.unit_price
                                                : 0) +
                                            " | "
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              ))
                            : ""}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Show;
