import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Http from "../Common/Http";
import Accounts from "./Sidebar/Accounts";
import Assets from "./Sidebar/Assets";
import BasicSetup from "./Sidebar/BasicSetup";
import Payroll from "./Sidebar/Payroll";
import Production from "./Sidebar/Production";
import Sales from "./Sidebar/Sales";
import SecondarySales from "./Sidebar/SecondarySales";
import StaffManagement from "./Sidebar/StaffManagement";
import SupplyChain from "./Sidebar/SupplyChain";
import Workplace from "./Sidebar/Workplace";


// import React, { useEffect, useState } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import Http from "../Common/Http";

// const Header = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [user, setUser] = useState({});
//   const [notification, setNotification] = useState({});

//   const fetchData = () => {
//     Http.get("/me").then((res) => {
//       setUser(res.data.data);
//     });
//   };

const Sidebar = () => {
  const [active, setActive] = useState(window.location.pathname);
  const [receiveData, setReceiveData] = useState([]);
  const location = useLocation();
  const [user, setUser] = useState({});


  // console.log("active", active);

  // const fetchData = () => {
  //   Http.get("/me").then((res) => {
  //     setUser(res.data.data);
  //   });
  // };

  const fetchData = () => {
    Http.get("/staff-type-check").then((res) => {
      setReceiveData(res.data);
    });
    Http.get("/me").then((res) => {
      setUser(res.data.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSidebarClick = (path) => {
    setActive(path);
  };

  useEffect(() => {
    $(".show-sub + .nav-sub").slideDown();

    $(".with-sub").on("click", function (e) {
      e.preventDefault();
      // Collapse all other submenus before toggling the current one
      $(".nav-sub").not($(this).next()).slideUp();
      $(".with-sub").not($(this)).removeClass("show-sub");

      // Now toggle the clicked submenu
      $(this).next().slideToggle();
      $(this).toggleClass("show-sub");
    });
  }, [receiveData]);

  if (location.pathname === "/" || location.pathname === "/login") return null;

  return (
    <div>
      <div className="am-sideleft">
        <ul className="nav am-sideleft-tab">
          <li className="nav-item w-100">
          <p className="nav-link tx-18" > <i className="fa fa-user"></i> &nbsp; {user.name} </p>
            {/* <a href="#mainMenu" className="nav-link">
              <i className="icon ion-ios-menu-outline tx-24" />
              <i className="fa fa-bars text-12"></i>
              <p className="nav-link" > {user.name} </p>
            </a> */}
          </li>
          {/* <li className="nav-item">
            <a href="#emailMenu" className="nav-link">
              <i className="icon ion-ios-email-outline tx-24" />
            </a>
          </li>
          <li className="nav-item">
            <a href="#chatMenu" className="nav-link">
              <i className="icon ion-ios-chatboxes-outline tx-24" />
            </a>
          </li>
          <li className="nav-item">
            <a href="#settingMenu" className="nav-link">
              <i className="icon ion-ios-gear-outline tx-24" />
            </a>
          </li> */}
        </ul>
        <div className="tab-content">
          <div id="mainMenu" className="tab-pane active">
            {/* {sidebarComponent} */}
            <ul className="nav am-sideleft-menu">
              <li className="nav-item">
                <Link
                  to="/dashboard"
                  className={`nav-link ${active === "/dashboard" ? "active" : ""}`}
                  onClick={() => handleSidebarClick("/dashboard")}
                >
                  <i className="fa fa-tachometer" aria-hidden="true"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              {/* Basic Setup */}
              {(receiveData === "admin" || receiveData === "hr") && <BasicSetup onMenuClick={handleSidebarClick} active={active} />}
              {/* WorkPlace */}
              {(receiveData === "admin" || receiveData === "hr") && <Workplace onMenuClick={handleSidebarClick} active={active} />}
              {/* Staff Management */}
              {(receiveData === "admin" || receiveData === "hr" || receiveData === "staff") && (
                <StaffManagement onMenuClick={handleSidebarClick} active={active} />
              )}
              {/* Payroll */}
              {(receiveData === "admin" || receiveData === "hr") && <Payroll onMenuClick={handleSidebarClick} active={active} />}
              {/* Accounts */}
              {(receiveData === "admin" || receiveData === "accounts") && <Accounts onMenuClick={handleSidebarClick} active={active} />}
              {/* Supply Chain */}
              {(receiveData === "admin" || receiveData === "supply_chain" || receiveData === "depot") && (
                <SupplyChain role={receiveData} onMenuClick={handleSidebarClick} active={active} />
              )}
              {/* Production */}
              {(receiveData === "admin" || receiveData === "supply_chain" || receiveData === "production" || receiveData === "depot") && (
                <Production role={receiveData} onMenuClick={handleSidebarClick} active={active} />
              )}
              {/* Assets */}
              {(receiveData === "admin" || receiveData === "supply_chain") && <Assets onMenuClick={handleSidebarClick} active={active} />}
              {/* Sales */}
              {(receiveData === "admin" || receiveData === "depot" || receiveData === "supply_chain") && (
                <Sales role={receiveData} onMenuClick={handleSidebarClick} active={active} />
              )}
              {/* Secondary Sales */}
              {receiveData === "admin" && <SecondarySales onMenuClick={handleSidebarClick} active={active} />}
              {/* Reports */}
              {receiveData != "hr" && (
                <li className="nav-item">
                  <Link to="/reports" className={`nav-link ${active === "/reports" ? "active" : ""}`} onClick={() => setActive("/reports")}>
                    <i className="fa fa-tachometer" aria-hidden="true"></i>
                    <span>Reports</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
