import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

import { Chip } from "primereact/chip";
import useDeleteData from "../../../../hooks/useDeleteData";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  // const fetchData = () => {
  //   Http.get("/roaster-holiday").then((res) => {
  //     setReceiveData(res.data);
  //   });
  // };

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/roaster-holiday");
      setReceiveData(res.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // to delete data
  const deleteData = useDeleteData(fetchData);

  // to show alert message
  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  // to show alert message from location state
  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  // columns for the datatable
  const cols = [
    { field: "name", header: "Name" },
    { field: "year", header: "Year" },
    {
      field: (data) =>
        data.items.map((item) => <Chip key={item.id} label={item.date} className="me-1" style={{ fontSize: "80%", fontWeight: 400 }} />),
      header: "Date",
    },
  ];

  // const deleteData = (id, deleteApiUrl, showMsg) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       Http.delete("/" + deleteApiUrl + "/" + id).then((res) => {
  //         setReceiveData(res.data);
  //         Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
  //       });
  //     }
  //   });
  // };

  return (
    <div>
      <IsLogin></IsLogin>
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Roaster Holiday List</h5>
            <Link to="/create-roaster-holiday-list" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i>Roaster Holiday
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-20 pd-sm-40">
              <div className="row">
                {/* <div className="table-responsive p-0 pb-2">
                  <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 datatable">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">S/N</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Name</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Year</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Date</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {receiveData.map((item, index) => (
                        <tr key={item.id}>
                          <td className="text-xs">{index + 1}</td>
                          <td className="text-xs">{item.name}</td>
                          <td className="text-xs">{item.year}</td>
                          <td className="text-xs">
                            {item.items.length > 0
                              ? item.items.map((data, index) => (
                                  <span key={index} className="badge bg-secondary me-1" style={{ fontSize: "80%", fontWeight: 300 }}>
                                    {data.date}
                                  </span>
                                ))
                              : ""}
                          </td>

                          <td className="text-xs">
                            <Link
                              to={{
                                pathname: "/edit-roaster-holiday-list/" + item.id,
                              }}
                              className="btn btn-primary btn-sm"
                            >
                              <i className="fa fa-pencil" aria-hidden="true"></i>
                            </Link>
                            <button className="btn btn-danger btn-sm ms-1" onClick={() => deleteData(item.id, "roaster-holiday", "Holiday")}>
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div> */}
                  <ReactDataTable cols={cols} receiveData={receiveData} isLoading={loading} actionTemplate={actionTemplate} deleteData={deleteData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const actionTemplate = (rowData) => {
  return (
    <div className="text-xs text-nowrap">
      <Link
        to={{
          pathname: "/edit-roaster-holiday-list/" + rowData.id,
          state: { id: rowData.id },
        }}
        className="btn btn-primary btn-sm"
        title="Edit"
      >
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
    </div>
  );
};

export default List;
