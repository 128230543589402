import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import useDeleteData from "../../../../../hooks/useDeleteData";
import Alert from "../../../../Common/Alert";
import Http from "../../../../Common/Http";
import IsLogin from "../../../../Common/IsLogin";
import ReactDataTable from "../../../../Common/ReactDataTable";

const Attendance = (props) => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [attendanceGiven, setAttendanceGiven] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    Http.get("/me").then((res) => {
      setAttendanceGiven(res.data.data.attendance_given_or_not);
      setLoading(false);
    }).catch((error) => {
      console.error('Error fetching data:', error);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData(); // Call fetchData when component mounts
  }, []); // Empty dependency array means this effect will only run once after the initial render

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * Math.PI / 180; // Convert degrees to radians
    const dLon = (lon2 - lon1) * Math.PI / 180; // Convert degrees to radians
    const a = 
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  };

  const getGeoLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            });
          },
          error => {
            reject(error);
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  const formSubmit = async () => {
    try {
      const location = await getGeoLocation();
      // Fixed coordinates to always compare against
      const fixedLat = 23.7644357;
      const fixedLng = 90.358958;

      const distance = calculateDistance(location.lat, location.lng, fixedLat, fixedLng);

      console.log(`Distance to fixed point is ${distance.toFixed(2)} kilometers.`);

      const is_movement_slip = false;
      const dataToSend = { ...inputs, ...location,is_movement_slip, distance };
      const response = await Http.post("/attendance-app-save", dataToSend);
      navigate("/attendance", {
        state: { message: response.data.message, type: "success" }
      });
    } catch (error) {
      if (error.response) {
        setError(error.response.data.errors);
      } else {
        console.error("Error obtaining location or sending data:", error);
      }
    }
  };
  // if (!attendanceGiven) {
  //   return (
  //     <div className="attendance-modal">
  //       <div className="modal-content">
  //         <h2>You must submit your attendance!</h2>
  //         <button onClick={formSubmit}>Submit Attendance</button>
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive p-0 pb-2">
            {attendanceGiven && (
              <button 
                className={`btn ${!attendanceGiven ? 'btn-primary' : 'btn-warning'} mg-b-10 float-right`} 
                onClick={() => {
                  if (window.confirm("Are you sure you want to submit this action?")) {
                    formSubmit();
                  }
                }}
              >
                <i className="fa fa-plus mg-r-10"></i>
                {!attendanceGiven ? 'New Attendance' : 'Checkout'}
              </button>
            )}


            <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 datatable">
              <thead>
                <tr>
                  <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                    S/N
                  </th>
                  <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                    Name
                  </th>
                  <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                    Lat
                  </th>
                  <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                    Lng
                  </th>
                  <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                    Mac Address
                  </th>
                  <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                    Checkin Distance
                  </th>
                  <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                    Checkout Distance
                  </th>
                  <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                    Start Date and Time
                  </th>
                  <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                    End Date and Time
                  </th>
                  <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                    Remarks
                  </th>
                </tr>
              </thead>

              <tbody>
                {Object.keys(props.data).map((item, index) => (
                  <tr key={props.data[index].id}>
                    <td className="text-xs">{index + 1}</td>
                    <td className="text-xs">
                      {props.data[index].staff
                        ? props.data[index].staff.name
                        : ""}
                    </td>
                    <td className="text-xs">{props.data[index].lat}</td>
                    <td className="text-xs">{props.data[index].lng}</td>
                    <td className="text-xs">{props.data[index].mac_address}</td>
                    <td className="text-xs">
                      {props.data[index].checkin_distance}
                    </td>
                    <td className="text-xs">
                      {props.data[index].checkout_distance}
                    </td>
                    <td className="text-xs">
                      {props.data[index].start_date_time}
                    </td>
                    <td className="text-xs">
                      {props.data[index].end_date_time}
                    </td>
                    <td className="text-xs">{props.data[index].remarks}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Attendance;
