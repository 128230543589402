import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Http from "../../../Common/Http";
import ReactDataTable from "../../../Common/ReactDataTable";

const View = (props) => {
  const [receiveData, setReceiveData] = useState({});
  const [resItems, setResItems] = useState([]);
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const res = await Http.get("/receipe/" + id);
        setReceiveData(res.data);
        const res2 = await Http.get("/receipe/" + id);
        setResItems(res2.data.items);
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  //   const fetchData = () => {
  //     Http.get("/receipe/" + id).then((res) => {
  //       setReceiveData(res.data);
  //     });
  //   };

  //   useEffect(() => {
  //     // DatatableFunc();
  //     fetchData();
  //   }, []);

  const cols = [
    { field: "product.name", header: "Name" },
    {
      field: (i) => {
        if (i.product.type === "packing_materials") {
          return "PM";
        } else if (i.product.type === "raw_materials") {
          return "RM";
        } else {
          return null;
        }
      },
      header: "Type",
    },
    { field: "qty", header: "QTY" },
    { field: "product.price", header: "Price" },
    // { field: "nameeeeeee", header: "Nameeeeeeeee" },
  ];

  const type =
    receiveData.product?.type === "finished_goods" ? "Finished Good" : null;

  return (
    <div>
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Recipe Details</h5>
        </div>

        <div className="am-pagebody  pb-0">
          <div className="card pd-10 pd-sm-40">
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-md-12 text-center pb-2">
                    <h4>
                      {/* {receiveData.product.name} ({receiveData.product.code}) Details */}
                      {receiveData.product?.name} Details
                    </h4>
                  <hr />
                  </div>
                  {/* <div className="container d-flex justify-between"> */}
                  <div className="col-6">
                    <b>Date:</b> {receiveData.date} <br />
                    <b>Batch Size:</b> {receiveData.batch_size} <br />
                    <b>Yield:</b> {receiveData.yield} <br />
                    <b>Overhead(%):</b> {receiveData.overhead_percent} <br />
                    <b>Overhead Amount:</b> {receiveData.overhead_amount} <br />
                  </div>
                  <div className="col-6 text-right">
                    <b>Receipt No:</b> {receiveData.receipt_no} <br />
                    <b>Price:</b> {receiveData.product?.price} <br />
                    <b>Type:</b> {type} <br />
                    <b>Code:</b> {receiveData.product?.code} <br />
                    {/* </div> */}
                  </div>
                </div>
                <hr />
                <ReactDataTable
                  cols={cols}
                  receiveData={resItems}
                  isLoading={loading}
                  // actionTemplate={actionTemplate}
                  // deleteData={deleteData}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
