import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

import useDeleteData from "../../../../hooks/useDeleteData";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/factory");
      setReceiveData(res.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  // to fetch data from the server
  useEffect(() => {
    fetchData();
  }, []);

  // to delete data
  const deleteData = useDeleteData(fetchData);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  // columns for the datatable
  const cols = [
    { field: "name", header: "Name" },
    { field: "location.name", header: "Location" },
  ];

  return (
    <div>
      <IsLogin></IsLogin>
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Factory</h5>
            <Link to="/factory/create" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i>Factory
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-20 pd-sm-40">
              <div className="row">
                {/* <div className="table-responsive p-0 pb-2">
                  <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 datatable">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">S/N</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Location</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Name</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {receiveData.map((item, index) => (
                        <tr key={item.id}>
                          <td className="text-xs">{index + 1}</td>
                          <td className="text-xs">{item.location.name}</td>
                          <td className="text-xs">{item.name}</td>
                          <td className="text-xs">
                            <Link to={{ pathname: "/factory/edit/" + item.id }} className="btn btn-primary btn-sm">
                              <i className="fa fa-pencil" aria-hidden="true"></i>
                            </Link>
                            <button className="btn btn-danger btn-sm ms-1" onClick={() => deleteData(item.id, "factory", "Factory")}>
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div> */}
                
                  <ReactDataTable cols={cols} receiveData={receiveData} isLoading={loading} actionTemplate={actionTemplate} deleteData={deleteData} />
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const actionTemplate = (rowData) => {
  return (
    <div className="text-xs text-nowrap">
      <Link
        to={{
          pathname: "/factory/edit/" + rowData.id,
          state: { id: rowData.id },
        }}
        className="btn btn-primary btn-sm"
        title="Edit"
      >
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
      {/* <button className="btn btn-danger btn-sm ms-1" onClick={() => deleteData(rowData.id, "factory", "Factory")} title="Delete">
        <i className="fa fa-trash"></i>
      </button> */}
    </div>
  );
};

export default List;
