import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Http from "../Common/Http";
import IsLogin from "../Common/IsLogin";
import AdminDashboard from "./Dashboard/Admin";
import StaffDashboard from "./Dashboard/Staff";
// import $ from "jquery";

const Dashboard = () => {
  const [receiveData, setReceiveData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const res = await Http.get("/staff-type-check");
        setReceiveData(res.data);
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      {loading ? (
        <ProgressSpinner className="dashLoad" />
      ) : (
        <>
          {(receiveData === "admin" ||
            receiveData === "depot" ||
            receiveData === "supply_chain") && <AdminDashboard />}
          {(receiveData === "staff" ||
            receiveData === "hr" ||
            receiveData === "so") && <StaffDashboard />}
        </>
      )}
    </div>
  );
};

export default Dashboard;
