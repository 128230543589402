import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../Common/Http";
import { LoaderComponent } from "../../Common/LoaderComponent";
import StaffProfile from "./StaffProfile";
import StaffDashboardContent from "./StaffDashbaordContent";

const Staff = (props) => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [receiveData, setReceiveData] = useState({});
  const [id, setId] = useState({});
  const [distance, setDistance] = useState('');
  const [loading, setLoading] = useState(false);
  const [attendanceGiven, setAttendanceGiven] = useState(false);

  const fetchData = () => {
    Http.get("/login-staff-detail").then((res) => {
      setId(res.data.id);
      const id = res.data.id;

      Http.get("/staff/" + id + "/edit").then((res) => {
        setReceiveData(res.data);
      });
    });

    Http.get("/me").then((res) => {
      setAttendanceGiven(res.data.data.attendance_given_or_not);
      setLoading(false);
    }).catch((error) => {
      console.error('Error fetching data:', error);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);


  // Helper function to calculate distance between two coordinates
  /*const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Earth's radius in kilometers
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = Math.sin(dLat / 2) ** 2 + 
              Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
              Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  };

  // Function to obtain the current geolocation
  const getGeoLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => resolve({ lat: position.coords.latitude, lng: position.coords.longitude }),
          error => reject(error)
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  const AttendanceForm = () => {
    const [showMovementSlip, setShowMovementSlip] = useState(false);
    const [movementType, setMovementType] = useState(''); // official or personal
    const [remarks, setRemarks] = useState('');
    const [time, setTime] = useState('');

    const formSubmit = async () => {
      try {
        const location = await getGeoLocation();
        const fixedLat = 23.7644357;
        const fixedLng = 90.358958;
        const distance = calculateDistance(location.lat, location.lng, fixedLat, fixedLng);
  
        console.log(`Distance to fixed point is ${distance.toFixed(2)} kilometers.`);
  
        if (distance > 0.01) { // More than 10 meters
          setShowMovementSlip(true); // Show the movement slip form
        } else {
          // Less than 10 meters, submit attendance

          const is_movement_slip = false;
          const dataToSend = { ...inputs, ...location, distance, is_movement_slip};
          const response = await Http.post("/attendance-app-save", dataToSend);
          navigate("/attendance", {
            state: { message: response.data.message, type: "success" }
          });
        }
      } catch (error) {
        console.error("Error obtaining location or sending data:", error);
        setError(error.response ? error.response.data.errors : error);
      }
    };
    const handleMovementSlipSubmit = async () => {
      try {
        const location = await getGeoLocation();
        const is_movement_slip = true;
        const end_time = time;
        const type = movementType;
        const dataToSend = { type, remarks, end_time, ...location, ...inputs, distance, is_movement_slip };
        
        const response = await Http.post("/attendance-app-save", dataToSend);
        
        // Assuming the response contains a `status` field to check for success
        if (response.data.status === true) {
          navigate("/attendance", {
            state: { message: response.data.message, type: "success" }
          });
        } else {
          console.error("Submission failed:", response.data.message);
          setError(response.data.message);
        }
      } catch (error) {
        console.error("Error submitting movement slip:", error);
        setError(error.response ? error.response.data.errors : error);
      }
    };
    

    return (
      <div className="attendance-modal">
        <div className="modal-content">
        {!showMovementSlip && (
          <div className="p-5">
          <h2 className="text-center">You must submit your attendance!</h2>
          <button className="mt-4 btn btn-info btn-block" onClick={formSubmit}>Submit Attendance</button>
          </div>
        )}
          {showMovementSlip && (
            <div className="movement-slip p-5">
              <h3 className="text-center">Movement Slip</h3><hr />
              <div className="form-control">
                <label>Type of Movement : </label>
                <select className="form-control" value={movementType} onChange={e => setMovementType(e.target.value)}>
                  <option value="">Select</option>
                  <option value="official">Official</option>
                  <option value="personal">Personal</option>
                </select>
              </div>
              <div className="form-control mt-4">
                <label>Expected Time to reach Office : </label>
                <input type="time"
                  className="form-control"
                  value={time}
                  onChange={e => setTime(e.target.value)}
                />
              </div>
              <div className="form-control mt-4">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  value={remarks}
                  onChange={e => setRemarks(e.target.value)}
                />
              </div>
              <button className="btn btn-success mt-4" onClick={handleMovementSlipSubmit}>Submit Movement Slip</button>
            </div>
          )}
        </div>
      </div>
    );
  };*/

  return (
    <div>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">{receiveData.name}</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            
            {/* {!attendanceGiven ? (
              <AttendanceForm />
            ) : (
              <> */}
                <div className="col-lg-12 dashboardTab">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="dashboard-tab-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#dashboard-tab"
                        type="button"
                        role="tab"
                        aria-controls="dashboard-tab"
                        aria-selected="true"
                      >
                        Dashboard
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="profile-tab-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-tab"
                        type="button"
                        role="tab"
                        aria-controls="profile-tab"
                        aria-selected="false"
                      >
                        Profile
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content mt-4" id="myTabContent">
                    <div className="tab-pane fade show active" id="dashboard-tab" role="tabpanel" aria-labelledby="home-tab">
                      <StaffDashboardContent attendanceGiven = {attendanceGiven} />
                    </div>
                    <div className="tab-pane fade show" id="profile-tab" role="tabpanel" aria-labelledby="salary-tab">
                      <StaffProfile />
                    </div>
                  </div>
                </div>
              {/* </>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staff;
