import React from "react";
import { Link } from "react-router-dom";

const SecondarySales = ({ onMenuClick, active }) => {
  return (
    <>
      <li className="nav-item">
        <Link
          to
          className={`nav-link with-sub ${
            active === "/secondary-sales" ||
            active === "/monthly-target" ||
            active === "/create-target" ||
            active === "/monthly-target-view" ||
            active === "/summary-report" ||
            active === "/daily-report" ||
            active === "/create-opening-stock" ||
            active === "/view-opening-stock"
              ? "active show-sub"
              : ""
          }`}
        >
          <i className="fa fa-briefcase" aria-hidden="true"></i>
          <span>Secondary Sales</span>
        </Link>
        <ul className="nav-sub">
          <li className="nav-item">
            <Link
              to="/monthly-target"
              className={`nav-link ${active === "/monthly-target" ? "active" : ""}`}
              onClick={() => onMenuClick("/monthly-target")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Monthly Target
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/create-target"
              className={`nav-link ${active === "/create-target" ? "active" : ""}`}
              onClick={() => onMenuClick("/create-target")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Create Target
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/monthly-target-view"
              className={`nav-link ${active === "/monthly-target-view" ? "active" : ""}`}
              onClick={() => onMenuClick("/monthly-target-view")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; View Monthly Target
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/summary-report"
              className={`nav-link ${active === "/summary-report" ? "active" : ""}`}
              onClick={() => onMenuClick("/summary-report")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Summary Report
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/daily-report"
              className={`nav-link ${active === "/daily-report" ? "active" : ""}`}
              onClick={() => onMenuClick("/daily-report")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Daily Report
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/create-opening-stock"
              className={`nav-link ${active === "/create-opening-stock" ? "active" : ""}`}
              onClick={() => onMenuClick("/create-opening-stock")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Create Distributor OS
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/opening-stock-view"
              className={`nav-link ${active === "/view-opening-stock" ? "active" : ""}`}
              onClick={() => onMenuClick("/view-opening-stock")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; View Distributor OS
            </Link>
          </li>
        </ul>
      </li>
    </>
  );
};

export default SecondarySales;
